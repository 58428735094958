<!-- 采购订单记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 订单号 -->
      <FormItem>
        <span>订单号：</span>
        <Input v-model="queryFrom.dept_id" class="iviewIptWidth250" placeholder="请输入"></Input>
      </FormItem>
      <!-- 供应商名称 -->
      <FormItem class="marginLeft40">
        <span>供应商名称：</span>
        <Input v-model="queryFrom.dept_id" class="iviewIptWidth250" placeholder="请输入"></Input>
      </FormItem>
      <!-- 下单时间 -->
      <FormItem class="marginLeft40">
        <span>下单时间：</span>
        <DatePicker type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 订单类型 -->
      <FormItem class="marginLeft40">
        <span>订单类型：</span>
        <Select placeholder="请选择" v-model="queryFrom.dept_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in thirdList" :value="item.dept_id" :key="index">{{ item.dept_name }}</Option>
        </Select>
      </FormItem>
      <!-- 经手人 -->
      <FormItem>
        <span>经手人：</span>
        <Select placeholder="请选择" v-model="queryFrom.dept_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in thirdList" :value="item.dept_id" :key="index">{{ item.dept_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20">合并导出</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData">
      <div slot="summary" class="summary">
        总数量：<span class="color389">{{ listData.length }}</span> 合计金额：<span class="color389">¥{{ totleMoney }}</span
        >
      </div>
    </Table>
    <Spin fix v-if="tabloading"></Spin>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'purchaseOrderRecordConsign',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach(item => {
        totle += item.money * item.num
      })
      return totle
    },
  },
  data() {
    return {
      tabloading: false,
      // 表格数据
      listData: [
        {
          name: '药物涂层冠脉球囊导管',
          num: 2,
          money: 1000.0,
        },
        {
          name: '药物涂层冠脉球囊导管',
          num: 3,
          money: 1000.0,
        },
      ],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '订单号',
          key: 'id',
          align: 'center',
        },
        {
          title: '供应商名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '产品',
          key: 'product',
          align: 'center',
        },
        {
          title: '数量',
          key: 'num',
          align: 'center',
        },
        {
          title: '订单金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.money)])
          },
        },
        {
          title: '下单时间',
          key: 'data',
          align: 'center',
        },
        {
          title: '订单类型',
          key: 'type',
          align: 'center',
        },
        {
          title: '经手人',
          key: 'person',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                    marginLeft: '40px',
                  },
                  on: {
                    click: () => {
                      // this.details(param.row)
                    },
                  },
                },
                '导出'
              ),
            ])
          },
        },
      ],
      // 表单拉下选项
      thirdList: [],
      // 查询参数
      queryFrom: {
        user_id: '',
        user_name: '',
        dept_id: '',
        subfamily_id: '',
      },
    }
  },
  methods: {
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsPurchaseOrderRecordConsign',
      })
    },
  },
}
</script>

<style scoped lang="less"></style>
